<template>
    <div>
        <h2>Status</h2>

        <div v-for="work in workAssigned" :key="work.id">
            <span>{{ work.username }}</span>
            <div class="dot-container">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            </div>
        </div>
        <span v-for="stat in stats" :key="stat.id">
            <b-badge
                class="badge"
                variant="dark"
                v-if="stat.id == details.status"
            >
                {{ stat.text }}</b-badge
            >
        </span>
    </div>
</template>
<script>
import { REQUEST_STATUS } from "../../common";

export default {
    props: {
        details: Object,
        workAssigned: Array,
    },
    data() {
        return {
            stats: REQUEST_STATUS,
        };
    },
};
</script>
<style scoped>
.dot {
    border-radius: 50%;
    height: 3px;
    width: 3px;
    background: linear-gradient(90deg, black, black);
    margin: 3px 0;
}
.dot-container {
    width: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}
.badge {
    margin-left: 5px;
}
</style>
