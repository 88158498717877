<template>
    <div v-if="userRole != 'Owner' && userRole != 'Tenant'">
        <h2>Comments</h2>
        <!-- v-if="userRole == 'Front Desk'" -->
        <form class="form-horizontal" @submit.prevent="addComment">
            <b-form-textarea
                id="textarea"
                placeholder="Add a comment..."
                v-model="comment"
                rows="5"
            ></b-form-textarea>
            <div class="mt-3 mb-3">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="!comment"
                >
                    Add Comment
                </button>
            </div>
        </form>

        <p v-for="comment in details.comments" :key="comment.id">
            {{ comment.comment }}
            <span>By - {{ comment.user_name }} | {{ comment.created_at }}</span>
        </p>
        <p v-if="details.comments && details.comments.length == 0">
            No comments yet
        </p>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        requestType: Number,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    data() {
        return {
            comment: null,
            userRole: null,
        };
    },
    methods: {
        addComment() {
            let payload = {
                request_id: this.details.id,
                request_type: this.requestType,
                comment: this.comment,
            };
            this.addCommentOnRequest(payload).then(() => {
                this.comment = null;
                this.$emit("refreshContent");
            });
            // console.log(payload);
        },
    },
};
</script>
<style scoped></style>
