<template>
    <div v-if="details.status != 6">
        <div v-if="userRole != 'Owner' && userRole != 'Tenant'">
            <h2>Audit Approval</h2>

            <p v-if="details.audit_approved_by != 0">
                Approved On :
                {{ details.audit_approval_date }}
            </p>

            <p v-if="details.audit_approved_by == 0 && userRole != 'Audit'">
                Approval Pending
            </p>

            <b-button
                variant="primary"
                v-if="
                    details.audit_approved_by == 0 &&
                    (userRole == 'Administrator' || userRole == 'Audit')
                "
                @click="approveAudit(details.id, requestType)"
                >Approve</b-button
            >
            <br />
            <h2>Management Approval</h2>

            <p v-if="details.management_approved_by != 0">
                Approved On :
                {{ details.management_approval_date }}
            </p>

            <p
                v-if="
                    details.management_approved_by == 0 &&
                    userRole != 'Management'
                "
            >
                Approval Pending
            </p>

            <b-button
                variant="primary"
                v-if="
                    details.management_approved_by == 0 &&
                    (userRole == 'Administrator' || userRole == 'Management')
                "
                @click="approveManagement(details.id, requestType)"
            >
                Approve
            </b-button>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        requestType: Number,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    data() {
        return {
            userRole: null,
            comment: null,
        };
    },
    methods: {
        approveAudit(id, requestType) {
            this.auditApproval(id, requestType, "Provide Audit Approval").then(
                () => {
                    this.$emit("refreshContent");
                }
            );
        },
        approveManagement(id, requestType) {
            this.managementApproval(
                id,
                requestType,
                "Provide Management Approval"
            ).then(() => {
                this.$emit("refreshContent");
            });
        },
    },
};
</script>
<style scoped></style>
