var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userRole != 'Owner' && _vm.userRole != 'Tenant')?_c('h2',[_vm._v("Actions")]):_vm._e(),_c('div',{staticClass:"row mt-2"},[(
                (_vm.userRole == 'Maintenance' ||
                    _vm.userRole == 'Administrator' ||
                    _vm.userRole == 'DLD' ||
                    _vm.userRole == 'Facility Management') &&
                _vm.details.status == 4 &&
                _vm.details.status != 2
            )?_c('div',{staticClass:"col-lg-4"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateRequestStatus(
                        _vm.details.id,
                        2,
                        _vm.requestType,
                        'Approve Request'
                    )}}},[_vm._v("Approve Completion")])],1):_vm._e(),(
                _vm.userRole == 'FM Executive' &&
                _vm.details.status == 1 &&
                _vm.details.status != 4 &&
                _vm.details.status != 6
            )?_c('div',{staticClass:"col-lg-4"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateRequestStatus(
                        _vm.details.id,
                        4,
                        _vm.requestType,
                        'Request For Completion'
                    )}}},[_vm._v("Request Completion")])],1):_vm._e(),(
                _vm.userRole == 'Front Desk' ||
                (_vm.userRole == 'Administrator' && _vm.details.status == 4)
            )?_c('div',{staticClass:"col-lg-4"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateRequestStatus(
                        _vm.details.id,
                        3,
                        _vm.requestType,
                        'Close Request'
                    )}}},[_vm._v("Close")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }