import { BASE_URL } from "../views/common";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            units: [],
            isUnitFetched: false,
        };
    },
    methods: {
        fetchUnits(projectId) {
            this.isUnitFetched = false;
            if (localStorage.cs_user_token) {
                // projects-available-units/
                console.log("the project id is " + projectId);
                axios
                    .get(
                        `${BASE_URL}projects-available-units-tenants/${projectId}?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.units = res.data.response;
                        this.isUnitFetched = true;
                    });
            }
        },

        viewDocument(url) {
            let fileExtension = url.split(".")[1].toLowerCase();

            if (
                fileExtension == "jpg" ||
                fileExtension == "jpeg" ||
                fileExtension == "png" ||
                fileExtension == "gif"
            ) {
                let routeData = this.$router.resolve({
                    name: "imagePreview",
                    query: {
                        data: url,
                    },
                });
                window.open(routeData.href, "_blank");
            } else if (fileExtension == "pdf") {
                let routeData = this.$router.resolve({
                    name: "pdfPreview",
                    query: {
                        data: url,
                    },
                });
                window.open(routeData.href, "_blank");
            }
        },
        auditApproval(id, requestType, message) {
            console.log(id, requestType);

            return Swal.fire({
                title: message,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    let payload = {
                        request_id: id,
                        request_type: requestType,
                    };

                    return axios
                        .post(
                            `${BASE_URL}request-audit-approval?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Successfully Approved",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            });
        },
        managementApproval(id, requestType, message) {
            console.log(id, requestType);
            return Swal.fire({
                title: message,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    let payload = {
                        request_id: id,
                        request_type: requestType,
                    };

                    return axios
                        .post(
                            `${BASE_URL}request-management-approval?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Successfully Approved",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            });
        },
        updateRequestStatus(id, status, requestType, message) {
            Swal.fire({
                title: message,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    let payload = {
                        request_type: requestType,
                        request_id: id,
                        status: status,
                    };

                    axios
                        .post(
                            `${BASE_URL}request-status-update?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Successfully updated status",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            });
        },

        addCommentOnRequest(payload) {
            console.log(payload);
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
            });
            return axios
                .post(
                    `${BASE_URL}request-comment?token=${localStorage.cs_user_token}`,
                    payload
                )
                .then((res) => {
                    loader.hide();
                    console.log(res);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Comment has been posted",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    loader.hide();
                    Swal.fire(
                        "Error",
                        "Something went wrong! Please try again",
                        "error"
                    );
                });
        },

        addRejectComment(payload) {
            console.log(payload);
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
            });
            return axios
                .post(
                    `${BASE_URL}request-reject?token=${localStorage.cs_user_token}`,
                    payload
                )
                .then((res) => {
                    loader.hide();
                    console.log(res);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Comment has been posted",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    loader.hide();
                    Swal.fire(
                        "Error",
                        "Something went wrong! Please try again",
                        "error"
                    );
                });
        },
        approvePaymentByAccounts(payload) {
            return Swal.fire({
                title: "Approve payment",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });

                    return axios
                        .post(
                            `${BASE_URL}accounts/approve?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Successfully approved payment",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                }
            });
        },

        homeAndCommercialApproval(reqId, department, type, url) {
            let payload = {
                id: reqId,
                dept: department,
                model: type,
            };
            console.log(payload);
            return Swal.fire({
                title: "Approve action",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    return axios
                        .post(
                            `${BASE_URL}${url}?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            loader.hide();
                            console.log(res);
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Successfully approved ",
                                showConfirmButton: false,
                                timer: 3000,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            loader.hide();
                            Swal.fire(
                                "Error",
                                "Something went wrong! Please try again",
                                "error"
                            );
                        });
                    //if
                }
            });
        },
        submitEngineeringComments(url, payload) {
            console.log(payload);
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
            });
            return axios
                .post(
                    `${BASE_URL}${url}?token=${localStorage.cs_user_token}`,
                    payload
                )
                .then((res) => {
                    loader.hide();
                    console.log(res);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Comment has been posted",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    loader.hide();
                    Swal.fire(
                        "Error",
                        "Something went wrong! Please try again",
                        "error"
                    );
                });
        },
        //  methods
    },

    mounted() {},
};
