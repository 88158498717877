<template>
    <div>
        <h2 v-if="userRole != 'Owner' && userRole != 'Tenant'">Actions</h2>
        <div class="row mt-2">
            <div
                class="col-lg-4"
                v-if="
                    (userRole == 'Maintenance' ||
                        userRole == 'Administrator' ||
                        userRole == 'DLD' ||
                        userRole == 'Facility Management') &&
                    details.status == 4 &&
                    details.status != 2
                "
            >
                <b-button
                    variant="primary"
                    @click="
                        updateRequestStatus(
                            details.id,
                            2,
                            requestType,
                            'Approve Request'
                        )
                    "
                    >Approve Completion</b-button
                >
            </div>
            <div
                class="col-lg-4"
                v-if="
                    userRole == 'FM Executive' &&
                    details.status == 1 &&
                    details.status != 4 &&
                    details.status != 6
                "
            >
                <b-button
                    variant="primary"
                    @click="
                        updateRequestStatus(
                            details.id,
                            4,
                            requestType,
                            'Request For Completion'
                        )
                    "
                    >Request Completion</b-button
                >
            </div>
            <div
                class="col-lg-4"
                v-if="
                    userRole == 'Front Desk' ||
                    (userRole == 'Administrator' && details.status == 4)
                "
            >
                <b-button
                    variant="primary"
                    @click="
                        updateRequestStatus(
                            details.id,
                            3,
                            requestType,
                            'Close Request'
                        )
                    "
                    >Close</b-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        requestType: Number,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    data() {
        return {
            userRole: null,
            comment: null,
        };
    },
};
</script>
<style scoped></style>
