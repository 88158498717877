var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.details.status != 6)?_c('div',[(_vm.userRole != 'Owner' && _vm.userRole != 'Tenant')?_c('div',[_c('h2',[_vm._v("Audit Approval")]),(_vm.details.audit_approved_by != 0)?_c('p',[_vm._v(" Approved On : "+_vm._s(_vm.details.audit_approval_date)+" ")]):_vm._e(),(_vm.details.audit_approved_by == 0 && _vm.userRole != 'Audit')?_c('p',[_vm._v(" Approval Pending ")]):_vm._e(),(
                _vm.details.audit_approved_by == 0 &&
                (_vm.userRole == 'Administrator' || _vm.userRole == 'Audit')
            )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approveAudit(_vm.details.id, _vm.requestType)}}},[_vm._v("Approve")]):_vm._e(),_c('br'),_c('h2',[_vm._v("Management Approval")]),(_vm.details.management_approved_by != 0)?_c('p',[_vm._v(" Approved On : "+_vm._s(_vm.details.management_approval_date)+" ")]):_vm._e(),(
                _vm.details.management_approved_by == 0 &&
                _vm.userRole != 'Management'
            )?_c('p',[_vm._v(" Approval Pending ")]):_vm._e(),(
                _vm.details.management_approved_by == 0 &&
                (_vm.userRole == 'Administrator' || _vm.userRole == 'Management')
            )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approveManagement(_vm.details.id, _vm.requestType)}}},[_vm._v(" Approve ")]):_vm._e()],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }