<template>
    <div v-if="userRole == 'Front Desk' || userRole == 'FM Executive'">
        <h2 v-if="details.status != 6">Rejection</h2>
        <h2 v-if="details.status == 6">Rejection Comments</h2>
        <form
            v-if="details.status != 6"
            class="form-horizontal"
            @submit.prevent="requestReject"
        >
            <b-form-textarea
                id="textarea"
                placeholder="Add a comment..."
                rows="5"
            ></b-form-textarea>
            <div class="mt-3 mb-3">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="!comment"
                >
                    Reject
                </button>
            </div>
        </form>
        <div v-if="details.status == 6">
            <p>
                {{ details.reject.reject_comment }}
                <span
                    >By - {{ details.reject.user_name }} |
                    {{ details.reject.created_at }}</span
                >
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        details: Object,
        requestType: Number,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    data() {
        return {
            userRole: null,
            comment: null,
        };
    },
    methods: {
        requestReject() {
            let payload = {
                request_id: this.details.id,
                request_type: this.requestType,
                ref_no: this.details.ref_no,
                reject_comment: this.comment,
            };
            console.log(payload);
            this.addRejectComment(payload);
        },
    },
};
</script>
<style scoped></style>
