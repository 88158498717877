import { render, staticRenderFns } from "./audit&ManagementApproval.vue?vue&type=template&id=ec94eb18&scoped=true&"
import script from "./audit&ManagementApproval.vue?vue&type=script&lang=js&"
export * from "./audit&ManagementApproval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec94eb18",
  null
  
)

export default component.exports